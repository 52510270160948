import styled from 'styled-components'
import { Grid, Card } from '@mui/material'

export const AppContainer = styled(Grid)`
  height: 100vh;
  background-color: #f1f5f8;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
`

export const CustomCard = styled(Card)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-height: 425px;
  height: 100%;
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 24px !important;
`
