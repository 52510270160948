import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { enappgyDataServiceApi } from './dataApi'
import { enappgyMetaDataServiceApi } from './metaDataApi'

import buildingsSlice from './slices/buildingsSlice'

export const store = configureStore({
  reducer: {
    [enappgyDataServiceApi.reducerPath]: enappgyDataServiceApi.reducer,
    [enappgyMetaDataServiceApi.reducerPath]: enappgyMetaDataServiceApi.reducer,
    buildings: buildingsSlice,
  },

  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      enappgyDataServiceApi.middleware,
      enappgyMetaDataServiceApi.middleware,
    ),
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)
