import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import enTranslation from '../services/translation/en.json'
import esTranslation from '../services/translation/es.json'
import nlTranslation from '../services/translation/nl.json'
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      fallbackLng: 'en', // Fallback language
      debug: process.env.NODE_ENV === 'development', // Enable debug logs in development
      detection: {
        order: ['navigator', 'htmlTag', 'querystring', 'cookie', 'localStorage', 'path', 'subdomain'],
        caches: ['cookie'],
      },
      interpolation: {
        escapeValue: false, // React already escapes values
      },
      resources: {
        en: {
          translation: enTranslation,
        },
        nl: {
          translation: nlTranslation,
        },
        es: {
          translation: esTranslation,
        },
      },
    },
    (err, t) => {
      // Callback when initialization is complete
      if (err)
        return console.error('Something went wrong during initialization:', err)
    },
  )

export default i18n
