import { format, subDays, parseISO, subMonths, subYears } from 'date-fns'
import { Constants } from './constants'

export const formatNumber = (value) => {
  let suffix = ''
  let number = value

  if (Math.abs(number) >= 1000000) {
    suffix = 'M'
    number = number / 1000000
  } else if (Math.abs(number) >= 1000) {
    suffix = 'K'
    number = number / 1000
  }

  const formattedNumber = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 1,
    minimumFractionDigits: 1,
  }).format(number)

  return `${formattedNumber}${suffix}`
}

export const getFilter = (type) => {
  const currentDate = new Date()
  const startDateHour = subDays(currentDate, 1)
  const startDateWeek = subDays(currentDate, 7)
  const startDateMonth = subMonths(currentDate, 12)
  const startDateYear = subYears(currentDate, 1)

  const filter = {
    objectType: Constants.OBJECT_TYPE,
    sensorType: Constants.SENSOR_TYPE,
    dateTimeStart: format(startDateHour, 'y-MM-dd HH:mm:00'), // Formats to '2023-08-11'
    dateTimeEnd: format(currentDate, 'y-MM-dd HH:mm:00'), // Formats to '2023-08-11'
    measurementType: Constants.MEASUREMENT_TYPE,
    bucketTimeWindow: Constants.HOUR,
  }

  switch (type) {
    case 'days':
      filter.bucketTimeWindow = Constants.DAY
      filter.dateTimeStart = format(startDateWeek, 'y-MM-dd HH:mm:00')
      break
    case 'month':
      filter.bucketTimeWindow = Constants.MONTH
      filter.dateTimeStart = format(startDateMonth, 'y-MM-dd HH:mm:00')
      break
    case 'year':
      filter.bucketTimeWindow = Constants.YEAR
      filter.dateTimeStart = format(startDateYear, 'y-MM-dd HH:mm:00')
      break
    default:
  }

  return filter
}

export const formatSeries = (energy, solar, type) => {
  const dateFormat = type === 'hour' ? 'HH:00' :   type === 'year'? 'yyyy' : type === 'month'? 'MMM' :'dd MMM'
  
  return (
    energy?.map(({ to, sum }, index) => {
      const solarValue = solar[index]?.sum || 0 // Assuming solar has a similar structure
      return {
        date: format(parseISO(to), dateFormat),
        energy: sum,
        solar: solarValue,
      }
    }) || []
  )
}
