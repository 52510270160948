import { useEffect, useState } from 'react'
import { useGetEnergyConsumptionByFilterQuery } from '../../redux/dataApi'
import { useSelector } from 'react-redux'
import {
  CommonSeriesSettings,
  Legend,
  Series,
  Format,
  ValueAxis,
  Label,
} from 'devextreme-react/chart'
import { useLottie } from 'lottie-react'
import loader from '../../resources/lotties/loader.json'
import { CustomCard } from '../../App.styled'
import { CardHeader } from '@mui/material'

import { CustomChart } from './EnergyChart.styled'
import { formatSeries, getFilter } from '../../helpers/functions'

import { useTranslation } from "react-i18next";

export default function EnergyChart(props) {
  const { t } = useTranslation();
  const { buildings } = useSelector((state) => state.buildings)
  const filterEnergy = getFilter(props.type)
  const filterSolar = getFilter(props.type)
  const buildingId = buildings[0]?.id
  const [chartData, setChartData] = useState([])
  // Automatically fetches data every minute because of the pollingInterval setting


  const { data: energy } = useGetEnergyConsumptionByFilterQuery(
    { filter: filterEnergy, buildingId },
    { skip: !buildings, pollingInterval: 600000 },
  )

  const { data: solar } = useGetEnergyConsumptionByFilterQuery(
    { filter: filterSolar, buildingId },
    { skip: !buildings, pollingInterval: 600000 },
  )

  useEffect(() => {
    if(energy){
      const formattedSeries = formatSeries(energy, props.solar, props.type)
      setChartData(formattedSeries)
    }
  }, [energy, props.solar, props.type, props.energy,])

  const customizeText= (arg)=> {
    return `${arg.valueText} kWh`;
  }
  const options = {
    animationData: loader,
    loop: true,
    style: {
      height: '100%',
      justifyContent: 'center',
      maxWidth: '3em',
      margin: '0 auto',
    },
  }
  const { View } = useLottie(options)
  const colorPalette = ['#28B4C8', '#78D237', '#28b4c8', '#78d237', '#ffd246']
  return (
    chartData.length > 0 ? (
      <CustomCard variant="outlined">
         <CardHeader title={t(`chart.chartTitle.${props.type}`)} />
        <CustomChart
          dataSource={chartData}
          id="chart"
          palette={colorPalette}
          resolveLabelOverlapping="hide"
        >
          <ValueAxis>
            <Label visible={true} customizeText={customizeText} />
          </ValueAxis>
          <CommonSeriesSettings argumentField="date" type="bar">
            {/* <Label visible={true}>
              <Format type="fixedPoint" precision={0} />
            </Label> */}
          </CommonSeriesSettings>
           <Series
            argumentField="date"
            valueField="energy"
            name={t('chart.energy')}
          /> 
          <Series
            argumentField="date"
            valueField="solar"
            name={t('chart.solar')}
          />
          <Legend verticalAlignment="bottom" horizontalAlignment="center" />
        </CustomChart>
      </CustomCard>
    ):(
      <div style={{ height: '100%' }}>{View}</div>
    )
  )
}
