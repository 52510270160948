import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useAuth0 } from '@auth0/auth0-react'

import { useLottie } from 'lottie-react'
import loader from './resources/lotties/loader.json'

import { Grid } from '@mui/material'
import { AppContainer } from './App.styled'
import EnergyChart from './components/EnergyChart/EnergyChart'
import LogoCard from './components/LogoCard/LogoCard'
import SavingsCard from './components/SavingsCard/SavingsCard'

import { initBuildings } from './redux/slices/buildingsSlice'
import {  useGetBuildingByUserIdQuery, useGetUserMetaDataByIdQuery } from './redux/metaDataApi'
import i18n from 'i18next';
import { dayEnergyConsumption, monthEnergyConsumption, weekEnergyConsumption, yearEnergyConsumption } from './resources/Data/Data'

function App() {
  const dispatch = useDispatch()
  const [localUserId, setLocalUserId] = useState(undefined)
  const { user, isLoading, isAuthenticated, loginWithRedirect } = useAuth0()
  const { sub: userId } = user || {}
  const tenantId = useSelector((state) => state.buildings.tenantId)
  const {
    data: userMetadata,
  } = useGetUserMetaDataByIdQuery("auth0|63110a991151a2dd290b9c3f")

  const { data } = useGetBuildingByUserIdQuery(
    { userId: userMetadata?.id, tenantId: tenantId },
    { skip: !tenantId },
  )
  const options = {
    animationData: loader,
    loop: true,
    style: {
      height: '100vh',
      justifyContent: 'center',
      maxWidth: '3em',
      margin: '0 auto',
    },
  }
  const { View } = useLottie(options)


  // useEffect(() => {
  //   if (isAuthenticated) {
  //     setLocalUserId(userId)
  //   }
  //   if (!isAuthenticated && !isLoading) {
  //     loginWithRedirect()
  //   }
  // }, [isAuthenticated, isLoading, loginWithRedirect, dispatch, userId])

  useEffect(() => {
    if (data) {
      const buildings = data.map((structure) => structure.building)
      dispatch(initBuildings(buildings))
    }
  }, [data, dispatch])

  if (isLoading) {
    return <div style={{ height: '100vh' }}>{View}</div>
  }


  if (userMetadata) {
    return (
      <AppContainer container spacing={2}>
        <Grid item xs={4}>
          <EnergyChart type={'hour'} energy={[]}  solar={dayEnergyConsumption}/>
        </Grid>
        <Grid item xs={4}>
          <EnergyChart  type={'days'}  energy={[]}  solar={weekEnergyConsumption} />
        </Grid>
        <Grid item xs={4}>
          <LogoCard />
        </Grid>
        <Grid item xs={4}>
          <EnergyChart type={'month'}  energy={[]}  solar={monthEnergyConsumption}  />
        </Grid>
        <Grid item xs={4}>
          <EnergyChart type={'year'}   energy={[]} solar={yearEnergyConsumption} />
        </Grid>
        <Grid item xs={4}>
          <SavingsCard />
        </Grid>
      </AppContainer>
    )
  }
}

export default App
