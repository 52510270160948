export const yearEnergyConsumption =[
    {
        "from": "2023-02-28T12:58:00",
        "to": "2023-12-31T12:58:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 31728
    },
    {
        "from": "2024-01-01T12:58:00",
        "to": "2024-02-28T12:58:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 1233
    }
] //fake data ready

export const weekEnergyConsumption =[
    {
        "from": "2024-02-21T12:58:00",
        "to": "2024-02-21T00:00:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 38
    },
    {
        "from": "2024-02-22T00:00:00",
        "to": "2024-02-22T00:00:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 19
    },
    {
        "from": "2024-02-23T00:00:00",
        "to": "2024-02-23T00:00:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 22
    },
    {
        "from": "2024-02-24T00:00:00",
        "to": "2024-02-24T00:00:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 30
    },
    {
        "from": "2024-02-25T00:00:00",
        "to": "2024-02-25T00:00:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 19
    },
    {
        "from": "2024-02-26T00:00:00",
        "to": "2024-02-26T00:00:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 48
    },
    {
        "from": "2024-02-27T00:00:00",
        "to": "2024-02-27T00:00:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 21
    },
    {
        "from": "2024-02-28T00:00:00",
        "to": "2024-02-28T12:58:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 110
    }
] //fake data ready

export const monthEnergyConsumption =[
    {
        "from": "2023-02-28T12:58:00",
        "to": "2023-02-28T12:58:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 1338
    },
    {
        "from": "2023-03-01T12:58:00",
        "to": "2023-03-31T12:58:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 2220
    },
    {
        "from": "2023-04-01T12:58:00",
        "to": "2023-04-30T12:58:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 3634
    },
    {
        "from": "2023-05-01T12:58:00",
        "to": "2023-05-31T12:58:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 4877
    },
    {
        "from": "2023-06-01T12:58:00",
        "to": "2023-06-30T12:58:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 5427
    },
    {
        "from": "2023-07-01T12:58:00",
        "to": "2023-07-31T12:58:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 4203
    },
    {
        "from": "2023-08-01T12:58:00",
        "to": "2023-08-31T12:58:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 3833
    },
    {
        "from": "2023-09-01T12:58:00",
        "to": "2023-09-30T12:58:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 3169
    },
    {
        "from": "2023-10-01T12:58:00",
        "to": "2023-10-31T12:58:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 1499
    },
    {
        "from": "2023-11-01T12:58:00",
        "to": "2023-11-30T12:58:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 674
    },
    {
        "from": "2023-12-01T12:58:00",
        "to": "2023-12-31T12:58:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 304
    },
    {
        "from": "2024-01-01T12:58:00",
        "to": "2024-01-31T12:58:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 550
    },
    {
        "from": "2024-02-01T12:58:00",
        "to": "2024-02-28T12:58:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 683
    }
] //fake data ready

export const dayEnergyConsumption = [
    {
        "from": "2024-02-27T12:58:00",
        "to": "2024-02-27T13:58:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 2
    },
    {
        "from": "2024-02-27T13:58:00",
        "to": "2024-02-27T14:58:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 13
    },
    {
        "from": "2024-02-27T14:58:00",
        "to": "2024-02-27T15:58:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 23
    },
    {
        "from": "2024-02-27T15:58:00",
        "to": "2024-02-27T16:58:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum":25
    },
    {
        "from": "2024-02-27T16:58:00",
        "to": "2024-02-27T17:58:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 20
    },
    {
        "from": "2024-02-27T17:58:00",
        "to": "2024-02-27T18:58:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 15
    },
    {
        "from": "2024-02-27T18:58:00",
        "to": "2024-02-27T19:58:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 6
    },
    {
        "from": "2024-02-27T19:58:00",
        "to": "2024-02-27T20:58:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 4
    },
    {
        "from": "2024-02-27T20:58:00",
        "to": "2024-02-27T21:58:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 1
    },
    {
        "from": "2024-02-27T21:58:00",
        "to": "2024-02-27T22:58:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 0.0
    },
    {
        "from": "2024-02-27T22:58:00",
        "to": "2024-02-27T23:58:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 0.0
    },
    {
        "from": "2024-02-27T23:58:00",
        "to": "2024-02-28T00:58:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 0.0
    },
    {
        "from": "2024-02-28T00:58:00",
        "to": "2024-02-28T01:58:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 0.0
    },
    {
        "from": "2024-02-28T01:58:00",
        "to": "2024-02-28T02:58:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 0.0
    },
    {
        "from": "2024-02-28T02:58:00",
        "to": "2024-02-28T03:58:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 0.0
    },
    {
        "from": "2024-02-28T03:58:00",
        "to": "2024-02-28T04:58:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 0.0
    },
    {
        "from": "2024-02-28T04:58:00",
        "to": "2024-02-28T05:58:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 0.0
    },
    {
        "from": "2024-02-28T05:58:00",
        "to": "2024-02-28T06:58:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 0.0
    },
    {
        "from": "2024-02-28T06:58:00",
        "to": "2024-02-28T07:58:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 0.0
    },
    {
        "from": "2024-02-28T07:58:00",
        "to": "2024-02-28T08:58:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 0.0
    },
    {
        "from": "2024-02-28T08:58:00",
        "to": "2024-02-28T09:58:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 0.0
    },
    {
        "from": "2024-02-28T09:58:00",
        "to": "2024-02-28T10:58:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 0.0
    },
    {
        "from": "2024-02-28T10:58:00",
        "to": "2024-02-28T11:58:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 0.0
    },
    {
        "from": "2024-02-28T11:58:00",
        "to": "2024-02-28T12:58:00",
        "avg": 0.0,
        "min": 0.0,
        "max": 0.0,
        "sum": 2
    }
] //fake data ready