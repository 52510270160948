import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  buildings: [],
  tenantId: '001',
}

export const buildingsSlice = createSlice({
  name: 'buildings',
  initialState,
  reducers: {
    initBuildings: (state, action) => {
      state.buildings = action.payload
    },
  },
})

export const { initBuildings } = buildingsSlice.actions

export default buildingsSlice.reducer
