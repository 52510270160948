import React from 'react'
import Enappgy from '../../resources/images/Logo.png'
import LRC from '../../resources/images/lrc.logo.png'
import Gemeente from '../../resources/images/gemeenteLogo.png'
import Dia from '../../resources/images/DiaLogo.png'
import { Typography } from '@mui/material'

import {
  LogoCardContainer,
  AddressContainer,
  LogosWrapper,
} from './LogoCard.styled'

export default function LogoCard() {
  const logos = [
    {
      label: 'DIA',
      logo: Dia,
      style: {
        width: 150,
        height: 46,
      },
    },
    {
      label: 'Gemeente',
      logo: Gemeente,
      style: {
        width: 83,
        height: 46,
      },
    },
    {
      label: 'Enappgy',
      logo: Enappgy,
      style: {
        width: 125.501,
        height: 32,
      },
    },
  ]
  return (
    <LogoCardContainer>
      <AddressContainer>
        <img
          src={LRC}
          alt="LRC"
          loading="lazy"
          style={{ width: 150, height: 87 }}
        />
        <Typography variant="h6" fontWeight={700}>
          Maartvlinder 1 | 3544 DA Utrecht, Netherlands
        </Typography>
      </AddressContainer>
      <LogosWrapper>
        {logos.map((item, index) => (
          <img
            key={index}
            src={item.logo}
            alt={item.label}
            style={item.style}
            loading="lazy"
          />
        ))}
      </LogosWrapper>
    </LogoCardContainer>
  )
}
