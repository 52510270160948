export const Constants = {
  TENANT_ID: '001',
  WEEK_DAYS: [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ],
  OBJECT_TYPE: 0,
  SENSOR_TYPE: 12,
  MEASUREMENT_TYPE: 1,
  DAY: 2,
  HOUR: 1,
  MONTH: 4,
  YEAR: 6
}
