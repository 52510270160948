import styled from 'styled-components'
import { Avatar, Box, Typography, Grid } from '@mui/material'

export const CustomAvatar = styled(Avatar)`
  padding: 16px;
  background-color: #c1e477 !important;
  color: #0f172a !important;
`

export const IconContainer = styled('div')`
  height: 24px;
  width: 24px;
`

export const SavingsContainer = styled(Box)`
  color: #0f172a !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 20px !important;
`

export const CustomNumber = styled(Typography)`
  color: #424242;
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.27px;
`

export const CustomLabel = styled(Box)`
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #8f8f8f;
`
export const SavingsNumber = styled(Typography)`
  color: #424242;
  text-align: center;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px;
`

export const IconWrapper = styled(Grid)`
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: space-between;
  margin-top: 35px;
`
