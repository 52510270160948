import React, { useMemo } from 'react'
import { CustomCard } from '../../App.styled'

import {
  CustomAvatar,
  IconContainer,
  SavingsContainer,
  CustomNumber,
  CustomLabel,
  SavingsNumber,
  IconWrapper,
} from './SavingsCard.styled'
import { GlobeEuropeAfricaIcon, BoltIcon } from '@heroicons/react/24/solid'
import { CloudIcon } from '@heroicons/react/24/outline'
import { CardHeader } from '@mui/material'
import { useGetEnergyConsumptionByFilterQuery } from '../../redux/dataApi'
import { useSelector } from 'react-redux'
import { formatNumber, getFilter } from '../../helpers/functions'
import { useTranslation } from "react-i18next";


export default function SavingsCard() {
  const { t } = useTranslation();
  const { buildings } = useSelector((state) => state.buildings)
  const filter = getFilter()
  const buildingId =  "c5752d42-72b6-496a-a352-b698d4df4c2e"
  const { data } = useGetEnergyConsumptionByFilterQuery({filter, buildingId}, { skip: !filter })
  const overallSum = data?.reduce((acc, item) => acc + item.sum, 0);
  const energyBaseline = buildings[1]?.energyBaseline
  const savings = -1 * energyBaseline * (1 - overallSum)

  const icons = useMemo(
    () => [
      {
        icon: CloudIcon,
        savings: formatNumber((savings * 0.64) / 1000),
        label:() => (
          <>
            {t('savings.CO2')}
            <sub>2</sub>
          </>
        )
      },
      {
        icon: GlobeEuropeAfricaIcon,
        savings: formatNumber(savings / 0.06),
        label: t('savings.tree'),
      },
      {
        icon: BoltIcon,
        savings: formatNumber(savings / 0.00000822),
        label: t('savings.phones'),
      },
    ],
    [savings, t],
  )

  return (
    <CustomCard variant="outlined">
      <CardHeader title={t('savings.title')} />
      <SavingsNumber variant='h2'>{formatNumber(savings)} kWh</SavingsNumber>
      <IconWrapper>
        {icons.map((item, index) => (
          <SavingsContainer key={index}>
            <CustomAvatar>
              <IconContainer>
                <item.icon />
              </IconContainer>
            </CustomAvatar>
            <CustomNumber>{item.savings}</CustomNumber>
            <CustomLabel>{typeof item.label === 'function' ? item.label() : item.label}</CustomLabel>
          </SavingsContainer>
        ))}
      </IconWrapper>
    </CustomCard>
  )
}
