import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { Provider } from 'react-redux'
import { store } from './redux/store'
import { Auth0Provider } from '@auth0/auth0-react'
import i18n from './services/i18n'
import { I18nextProvider } from 'react-i18next'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="dev-enappgy-io.eu.auth0.com"
      clientId="TO6NZPUeNZUrf2hpuIuFj0ECZE3OQQiO"
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
    >
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </Provider>
    </Auth0Provider>
  </React.StrictMode>,
)
